<template>
  <div class="register" :style="{backgroundImage:'url('+base_src+'register-01.png)'}">
    <template v-for="(item,index) in configData[cur]">
      <div class="title" v-if="!item.type">
        <div class="main-title">{{item.key}}</div>
        <div class="subtitle" v-if="!!item.subTitle">
          <span v-for="sub in item.subTitle">{{sub}}</span>
        </div>
      </div>
      <t-input class="form" :label="item.key" v-model="data[item.prop]" :props="item.property" :readonly="item.readonly" v-if="item.type==='input'" :placeholder="item.placeholder"></t-input>
      <t-input class="form" :label="item.key" :options="item.options" :props="item.property" @change="onChange($event,item,index)" v-model="data[item.prop]" :readonly="item.readonly" v-if="item.type==='select'" @click="onJump(item)" select :placeholder="item.placeholder"></t-input>
      <div class="row form" v-if="item.prop === 'student_attend_graduation_school'">
        <div class="key">学校简称</div>
        <div class="val">{{data["student_attend_graduation_school_jc"]}}</div>
      </div>
      <t-input class="form" :label="item.key" :options="item.options" :props="item.property" @change="onChange($event,item,index)" v-model="data[item.prop]" :readonly="item.readonly" v-if="item.type==='cascader'" @click="onJump(item)" cascader :placeholder="item.placeholder"></t-input>
    </template>
    <t-button style="margin-top: 32rem;" :disabled="disabled" type="primary" @click="onNext">下一步</t-button>
  </div>
</template>

<script setup>
import {computed, getCurrentInstance, nextTick, onActivated, onMounted, reactive, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import config from "./config/step.js"
import tools from "../../utils/tools.js";
import axios from "../../axios/index.js";
import {useStore} from "vuex";
import {Toast} from "vant";
import {addressMap, formatSelect, setCascader, showLoading} from "../../utils/utils";
import {getAddressInfo, getUserInfo,getBaseConfig as GBC} from "../../utils/api.js";
import constants from "../../utils/constants";
const store = useStore();
const data = ref({});
const route = useRoute();
const configData = ref(config);
const router = useRouter();
const {proxy,ctx} = getCurrentInstance();
const base_src = proxy.$resource;
let baseConfig = store.getters.baseConfig;
let scroll = 0;
let cur = ref(Number(route.query.step) || 0);
const show = ref(false);

store.commit("setRegisterInstance",getCurrentInstance());
// 按钮禁用判定
const disabled = computed(()=>{
  let [pn,pi,sn,si,sna,srp,src,sra,scp,scc,sca,scad,saro,sag,sagp,sagc,sags,sagcl,sagclo,sar,sas,ssa,shl,sid,p,c,ti,st,m] = [
      data.value.parent_name,   //
      data.value.parent_idcard,   //
      data.value.student_name,    //
      data.value.student_idcard,    //
      data.value.student_nation,    //
      data.value.student_register_province,   //              学生户籍地址-省份
      data.value.student_register_city,   //          学生户籍地址-城市
      data.value.student_register_area,   //          学生户籍地址-区县
      data.value.student_communication_province,    //           学生通讯地址-省份
      data.value.student_communication_city,    //       学生通讯地址-城市
      data.value.student_communication_area,    //       学生通讯地址-区县
      data.value.student_communication_address,   //           学生通讯地址-详细地址
      data.value.student_attend_road ,   //       学生就读途径
      data.value.student_attend_graduation ,   //       初中毕业年份
      data.value.student_attend_graduation_province,    //                初中毕业省份
      data.value.student_attend_graduation_city,    //            初中毕业城市
      data.value.student_attend_graduation_school,    //            初中毕业学校
      data.value.student_attend_graduation_class,   //             初中毕业班级
      data.value.student_attend_graduation_class_other,   //        初中毕业班级为其他时 , 展示该字段
      data.value.student_attend_repetition,   //       初中是否留级
      data.value.student_attend_suspend,    //    初中是否休学
      data.value.student_senior_achievement,    //        中考成绩估计
      data.value.student_high_languages,    //    高中学习语种
      data.value.school_id,   //         期望报名学校
      data.value.phone,   //   报名手机号码
      data.value.code,    //  验证码
      data.value.teacher_id,    //  咨询老师id
      data.value.study_type,    //  学习方向
      data.value.major,    //  专业方向
  ];
  switch (cur.value) {
    case 0:
      return tools.isEmpty([pn,pi],"||")
    case 1:
      return tools.isEmpty([sn,si],"||")
    case 2:
      return tools.isEmpty([sna,srp,src,sra,sca,scp,scc,scad],"||")
    case 3:
      if (sagcl === "其他")
        return tools.isEmpty([saro,sag,sagp,sagc,sags,sagcl,sagclo,sar,sas],"||")
      else
        return tools.isEmpty([saro,sag,sagp,sagc,sags,sagcl,sar,sas],"||")
    case 4:
      return tools.isEmpty([ssa,shl,sid,ti,st,m],"||")
  }
})
// 数据渲染
config.forEach(step=>{
  step.forEach(item=>{
    if (item.prop != null)
      data.value[item.prop] = "";
  })
})

const direct = reactive({
  study:[],
  major:[]
})
function setMajor(){
  if (data.value.study_type){
    direct.major = data.value.study_type.major;
    let mj = direct.major.some(item=>item.value === (data.value.major||{}).value);
    if (!mj){
      data.value.major = null;
    }
    configData.value[4][5].readonly = false;
    configData.value[4][5].options = direct.major;
  }
}
// 获取专业方向
function getDirect(){
  return axios.get("api/recruit/common/major").then(res=>{
    direct.study = JSON.parse(JSON.stringify(res).replaceAll("id","value").replaceAll("name","text"));
    setMajor();
  })
}
// 获取公用配置
function getBaseConfig(constraint = false){
  let setBase = (res)=>{
    res = res || baseConfig;
    // 学生就读途径
    configData.value[3][1].options = formatSelect(res.road);
    // 初中毕业年份
    configData.value[3][2].options = res.year;
    // 初中毕业班级
    configData.value[3][6].options = res.middle_class;
    // 是否留级
    configData.value[3][7].options = formatSelect(res.repetition);
    // 是否休学
    configData.value[3][8].options = formatSelect(res.suspend);
    // 高中期望语种
    configData.value[4][3].options = formatSelect(res.foreign_language)
    // 整体学习方向
    getDirect().then(res=>{
      configData.value[4][4].options = direct.study;
    })


  }
  if (!baseConfig || constraint){
    GBC().then(res=>{
      res.middle_class.push("其他");
      store.commit("setBaseConfig",res)
      setBase(res);
    })
    // 获取报名学校
    axios.get("api/recruit/common/consulting-school").then(res=>{
      // 期望报名学校
      configData.value[4][7].options = formatSelect(res.entity_school,"school_name","id");
    })
  }else setBase();
}

/**
 * @description 检测身份证号
 * @param {1|2} type 1: 家长  2: 学生
 */
function checkIdCard(type){
  if (show.value) return
  show.value = true;
  let d = {
    type
  };
  if (type === 1){
    d = Object.assign({},d,{
      idCard:data.value.parent_idcard,
      name: data.value.parent_name,
      module: 21
    })
  }else{
    d = Object.assign({},d,{
      idCard:data.value.student_idcard,
      name: data.value.student_name,
      module: 22
    })
  }
  return axios.get("/api/recruit/common/identity", {params: d})
}
// 点击选择框跳转
function onJump(item){
  let prop = item.prop;
  let query = {
  };
  scroll = window.scrollY;
  let getKeyVal = (key)=>{
    if (!data.value[key])return null
    else return data.value[key].value
  }
  if (!!item.router){
    // 学生户籍省份
    if (prop === "student_register_province"){
      query = {
        title:encodeURI("选择省份"),
        code:"000000",
        prop
      }
      //学生户籍城市
    }else if(prop === "student_register_city"){
      let val = getKeyVal("student_register_province")
      if (val != null){
        query = {
          title:encodeURI("选择城市"),
          code:val,
          prop
        }
      }else{
        return Toast({
          message:"请选择省份"
        })
      }
      // 学生户籍区县
    }else if(prop === "student_register_area"){
      let val = getKeyVal("student_register_city")
      if (val != null){
        query = {
          title:encodeURI("选择区县"),
          code:val,
          prop
        }
      }else{
        return Toast({
          message:"请选择城市"
        })
      }
      // 学生通信省份
    }else if(prop === "student_communication_province"){
      query = {
        title:encodeURI("选择省份"),
        code:"000000",
        prop
      }
      // 学生通信城市
    }else if(prop === "student_communication_city"){
      let val = getKeyVal("student_communication_province")
      if (val != null){
        query = {
          title:encodeURI("选择城市"),
          code:val,
          prop
        }
      }else{
        return Toast({
          message:"请选择省份"
        })
      }
      // 学生通信区县
    }else if(prop === "student_communication_area"){
      let val = getKeyVal("student_communication_city")
      if (val != null){
        query = {
          title:encodeURI("选择区县"),
          code:val,
          prop
        }
      }else{
        return Toast({
          message:"请选择城市"
        })
      }
    }else if(prop === "student_attend_graduation_province"){
      query = {
        title:encodeURI("选择毕业省份"),
        code:"000000",
        prop
      }
    }else if(prop === "student_attend_graduation_city"){
      let val = getKeyVal("student_attend_graduation_province")
      if (val != null){
        query = {
          title:encodeURI("选择毕业城市"),
          code:val,
          prop
        }
      }else{
        return Toast({
          message:"请选择毕业省份"
        })
      }
    }else if(prop === "student_attend_graduation_school"){
      let val = getKeyVal("student_attend_graduation_city")
      if (val != null){
        query = {
          title:encodeURI("选择初中历史就读学校"),
          code:val,
          prop,
          storeName:"registerCache",
          province:"student_attend_graduation_province",
          city:"student_attend_graduation_city",
        }
      }else{
        return Toast({
          message:"请选择毕业城市"
        })
      }
    }else if(prop === "teacher_id"){
      query.prop=prop;
    }
    router.push({
      path:item.router,
      query
    })
  }else if(prop === "school_id"){
    getBaseConfig(true); // 实时更新期望报名学校
  }else if(item.prop === "major" && tools.isEmpty(data.value.study_type)){
    Toast({
      message:"请先选择高中学习方向"
    })
  }
}
// 数据初始请求
function getData() {
  if (cur.value === 0){
    let userBaseInfo = store.getters.userBaseInfo;
    // 获取用户注册信息  用于默认值展示
    if (!userBaseInfo)
      getUserInfo().then(res=>{
        store.commit("setUserBaseInfo",res);
        setDefault(res)
      })
    else setDefault(userBaseInfo)
  }
}
watch(data,(n,o)=>{
  checkData(n,o,"student_register_province")
  checkData(n,o,"student_register_city")
  checkData(n,o,"student_communication_province")
  checkData(n,o,"student_communication_city")
  checkData(n,o,"student_attend_graduation_province")
  checkData(n,o,"student_attend_graduation_city")
  if (n.student_attend_graduation_class !== "其他"){
    n.student_attend_graduation_class_other = null;
  }
  if(n.student_attend_graduation_school)n.student_attend_graduation_school_jc = n.student_attend_graduation_school.alias;
  store.commit("setRegisterCache",n)
},{
  deep:true
})
onMounted(()=>{
  document.querySelector(".register").style.height = window.innerHeight+'px';
  onChange(data.value.student_attend_graduation_class,configData.value[3][6])
})

/**
 * @description 数据监听检测
 * @param n 新数据
 * @param o 旧数据
 * @param prop 对应的键
 */
function checkData(n,o,prop){
  let isComp = prop.indexOf("communication") > -1;
  let isAtt = prop.indexOf("attend") > -1;
  let keySp = prop.split("_");
  let key = keySp[keySp.length - 1];
  let maps = {
    "province":"city",
    "city":"area",
  }
  // 操作
  let handle = (a)=>{
    if (maps[key] == null)return;
    let k = a + maps[key];
    switch (prop){
      case "student_attend_graduation_city":
        k = "student_attend_graduation_school";
        break;
    }
    if (data.value[k])
      data.value[k] = ""
  }
  if (!!o[prop]){
    if (n[prop].value !== o[prop].value){
      if (isComp){
        handle("student_communication_")
      }else if(isAtt){
        handle("student_attend_graduation_")
      }else{
        handle("student_register_")
      }
    }
  }
}
onActivated(()=>{
  init();

})
function init(){
  const cache = store.getters.registerCache;
  if (!!cache){
    data.value = cache;
  }
}
init();
/**
 * @description 设置默认值
 * @param res
 * @param address
 */
const setDefault = (res,address)=>{
  if (!address){
    // 家长姓名
    data.value.parent_name = res.name;
    // 学生姓名
    data.value.student_name = res.student_name;
    // 手机号
    data.value.phone = res.student_name;
    // 设置默认学校
    let t = setInterval(()=>{
      let bc = store.getters.baseConfig;
      if (!!bc){
        let school = store.getters.baseConfig.school[res.consulting_school];
        data.value.school_id = school?{text:school,value:res.consulting_school}:null;
        // 休学配置
        let suspend = store.getters.baseConfig.suspend;
        // 留学配置
        let repetition = store.getters.baseConfig.repetition;
        // 就读途径配置
        let road = store.getters.baseConfig.road;
        // 毕业年份
        let year = store.getters.baseConfig.year;
        // 外语语种配置
        let foreign_language = store.getters.baseConfig.foreign_language;
        // 学习方向配置
        let direction = store.getters.baseConfig.direction1;
        // 是否休学
        let sk = Object.keys(suspend)[0];
        data.value.student_attend_suspend = {text:suspend[sk],value:sk}
        // 是否留级
        let rk = Object.keys(repetition)[0];
        data.value.student_attend_repetition = {text:repetition[rk],value:rk}
        // 学生就读途径
        let rok = Object.keys(road)[0];
        data.value.student_attend_road = {text:road[rok],value:rok}
        // 毕业年份
        data.value.student_attend_graduation = year[0];
        // 语种
        let fk = Object.keys(foreign_language)[0];
        data.value.student_high_languages = {text:foreign_language[fk],value:fk}
        clearInterval(t)
      }
    },500);

  }else{
    let mapAddr = addressMap(address);
    let {city,county,province} = res.student_id_info;
    let [mc,mco,mp] = [mapAddr[city],mapAddr[county],mapAddr[province]];
    // 默认值 省
    if (mp)
    {
      // 学生户籍地址
      data.value.student_register_province = data.value.student_register_province ||  {text:mp.address_name,value:province};
      // 学生通讯地址
      data.value.student_communication_province = data.value.student_communication_province || {text:mp.address_name,value:province};
      // 学生毕业地址
      data.value.student_attend_graduation_province = data.value.student_attend_graduation_province || {text:mp.address_name,value:province};
    }
    // 默认值 市
    if (mc)
    {
      // 学生户籍地址
      data.value.student_register_city = data.value.student_register_city || {text:mc.address_name,value:city};
      // 学生通讯地址
      data.value.student_communication_city = data.value.student_communication_city || {text:mc.address_name,value:city};
      // 学生毕业地址
      data.value.student_attend_graduation_city = data.value.student_attend_graduation_city || {text:mc.address_name,value:city};
    }
    // 默认值 区
    if (mco)
    {
      // 学生户籍地址
      data.value.student_register_area = data.value.student_register_area || {text:mco.address_name,value:county};
      // 学生通讯地址
      data.value.student_communication_area = data.value.student_communication_area || {text:mco.address_name,value:county};
    }
    showLoading(false)
  }

}


// 点击下一步执行的操作
function onNext(){
  let curData = configData.value[cur.value];
  let flag = true;
  // 正则匹配
  curData.forEach(item=>{
    if (!item.reg || !flag)return null;
    const isReg = typeof item.reg === 'object';
    const val = data.value[item.prop];
    if (isReg){
      if (!item.reg.test(val)){
        flag = false;
      }
    }else{
      if (!tools.checkInput(item.reg,val)){
        flag = false;
      }

    }
    if (!flag)Toast({
      message:item.key+"格式错误, 请检查"
    })

  })
  if (!flag)return;
  // 下一步
  let btn_next = ()=>{
    // 配置缓存
    store.commit("setRegisterCache",data.value);
    cur.value >= 4 ?router.push('/register-phone?type=3'):router.push({path:'/register',query:{step:cur.value + 1}});
    nextTick(()=>{
      setTimeout(()=>{
        document.querySelector('.register').scrollTo({
          top:0,
          behavior: "smooth"
        });
      },200)
    })
  }
  if (cur.value === 0){
    checkIdCard(1).then(res=>{
      btn_next();
    }).finally(() => show.value = false)
  }else if (cur.value === 1){
    showLoading(true);
    checkIdCard(2).then(res=>{
      store.commit("setUserBaseInfo",{
        student_id_info:res
      });
      // 获取学生户籍信息
      let addressCache = tools.getSession(constants.allAddressCache);
      if (!addressCache){
        getAddressInfo().then(res=>{
          tools.setSession(constants.allAddressCache,res);
          setDefault(store.getters.userBaseInfo,res);
        }).catch(res=>showLoading(false))
      }else{
        setDefault(store.getters.userBaseInfo,addressCache);
      }
      btn_next();
    }).catch(err => showLoading(false)).finally(() => show.value = false)
  }else btn_next();
}
// 获取数据
getData();
getBaseConfig();
router.beforeEach((to, from, next)=>{
  cur.value = Number(to.query.step) || 0;
  next();
})
function onChange(e,item,index) {
  if (item.prop === "student_attend_graduation_class"){
    if (e === "其他" && configData.value[3][7].prop !== "student_attend_graduation_class_other"){
      let cp = {
        prop:"student_attend_graduation_class_other",
        "key": "请输入初中毕业班级",
        "type": "input",
        "placeholder": "请输入",
        property:{
          maxlength:50
        }
      };
      configData.value[3].splice(7,0,cp);
    }else if(e !== "其他" && configData.value[3][7].prop === "student_attend_graduation_class_other"){
      configData.value[3].splice(7,1);
    }
  }else if(item.prop === "study_type"){
    setMajor();
  }
}
</script>
<script>
let sy = 0;
export default {
  beforeRouteLeave(to,from,next){
    let dom = sy = document.querySelector(".register");
    if (from.name.indexOf("register") > -1 && !!dom){
      sy = dom.scrollTop;
    }
    next();
  },
  activated() {
    let dom = document.querySelector('.register');
    if (!dom)return;
    dom.scrollTo({
      top:sy
    })
    sy = 0;
  }
}
</script>
<style lang="scss" scoped>
.register{
  min-height: calc(100vh - 82rem);
  overflow-y: scroll;
  box-sizing: border-box;
  //background-color: black;
  background-size: 100% 100%;
  padding:32rem 40rem 50rem;
  .title{
    margin-bottom: 25rem;
    padding-top: 18rem;
    .main-title{
      font-size: 18rem;
      font-weight: 600;
    }
    .subtitle{
      font-size: 10rem;
      margin-top: 5rem;
      color: rgba(0, 0, 0, 0.5);
      p{
        margin: 0;
      }
    }
  }
  .form{
    margin-bottom: 18rem;

  }
  .row{
    padding: 0 16rem;

    display: flex;
    justify-content: space-between;
  }
}
</style>
