export default [
	{"path":"/2024-online/exam-city","meta":{"title":"选择学生参加中考的城市"},"component":()=>import('@/pages/2024-online/exam-city.vue')},
	{"path":"/2024-online/home","meta":{"title":"首页","onHistory":true},"component":()=>import('@/pages/2024-online/home.vue')},
	{"path":"/2024-online/list/bank","meta":{"title":"开户行"},"component":()=>import('@/pages/2024-online/list/bank.vue')},
	{"path":"/2024-online/living-costs/order","meta":{},"component":()=>import('@/pages/2024-online/living-costs/order.vue')},
	{"path":"/2024-online/living-costs/student-list","meta":{"title":"学生列表"},"component":()=>import('@/pages/2024-online/living-costs/student-list.vue')},
	{"path":"/2024-online/login","meta":{"title":"登录","onHistory":true},"component":()=>import('@/pages/2024-online/login.vue')},
	{"path":"/2024-online/order","meta":{"onHistory":true},"component":()=>import('@/pages/2024-online/order.vue')},
	{"path":"/2024-online/parent-register","meta":{"title":"注册家长信息"},"component":()=>import('@/pages/2024-online/parent-register.vue')},
	{"path":"/2024-online/pay/offline","meta":{"title":"提交成功","onHistory":true},"component":()=>import('@/pages/2024-online/pay/offline.vue')},
	{"path":"/2024-online/pay","meta":{"title":"选择付款方式"},"component":()=>import('@/pages/2024-online/pay.vue')},
	{"path":"/2024-online/renew/order","meta":{"onHistory":true},"component":()=>import('@/pages/2024-online/renew/order.vue')},
	{"path":"/2024-online/renew/student-list","meta":{"title":"学生列表","pullRefresh":true},"component":()=>import('@/pages/2024-online/renew/student-list.vue')},
	{"path":"/2024-online/school-of-graduation/list","meta":{"title":"选择学生初中毕业学校"},"component":()=>import('@/pages/2024-online/school-of-graduation/list.vue')},
	{"path":"/2024-online/sign-in/info-confirm","meta":{"title":"学生报名信息核实"},"component":()=>import('@/pages/2024-online/sign-in/info-confirm.vue')},
	{"path":"/2024-online/sign-in/phone","meta":{"title":"签约手机号验证"},"component":()=>import('@/pages/2024-online/sign-in/phone.vue')},
	{"path":"/2024-online/sign-in/student-info","meta":{"title":"学生分班信息"},"component":()=>import('@/pages/2024-online/sign-in/student-info.vue')},
	{"path":"/2024-online/sign-in/student-list","meta":{"title":"学生列表"},"component":()=>import('@/pages/2024-online/sign-in/student-list.vue')},
	{"path":"/2024-online/sign-in/success-sign","meta":{"title":"签约成功"},"component":()=>import('@/pages/2024-online/sign-in/success-sign.vue')},
	{"path":"/2024-online/sign-in/success-submit","meta":{"title":"提交成功","onHistory":true},"component":()=>import('@/pages/2024-online/sign-in/success-submit.vue')},
	{"path":"/2024-online/sign-msg","meta":{"title":"签约提示"},"component":()=>import('@/pages/2024-online/sign-msg.vue')},
	{"path":"/2024-online/status","meta":{"title":"系统提示","onHistory":true},"component":()=>import('@/pages/2024-online/status.vue')},
	{"path":"/2024-online/student-info","name":"student-info","meta":{"title":"管理学生"},"component":()=>import('@/pages/2024-online/student-info.vue')},
	{"path":"/2024-online/student-list","meta":{"title":"学生列表","pullRefresh":true},"component":()=>import('@/pages/2024-online/student-list.vue')},
	{"path":"/2024-online/successful","meta":{"title":"报名成功","onHistory":true},"component":()=>import('@/pages/2024-online/successful.vue')},
	{"path":"/2024-online/supplement","meta":{"title":"录取补充信息"},"component":()=>import('@/pages/2024-online/supplement.vue')},
	{"path":"/2024-online/teacher-list/custom","meta":{"title":"请填写内推老师"},"component":()=>import('@/pages/2024-online/teacher-list/custom.vue')},
	{"path":"/2024-online/teacher-list/index","meta":{"title":"选择报名"},"component":()=>import('@/pages/2024-online/teacher-list/index.vue')},
	{"path":"/2024-online/teacher-list/list","meta":{"title":"初升高咨询服务报名"},"component":()=>import('@/pages/2024-online/teacher-list/list.vue')},
	{"path":"/2024-online/wait-pay","meta":{"title":"待支付订单"},"component":()=>import('@/pages/2024-online/wait-pay.vue')},
	{"path":"/edit-form/index/:step","meta":{"keepAlive":false},"name":"edit-form","component":()=>import('@/pages/EditForm/index.vue')},
	{"path":"/edit-form/preview","meta":{"title":"信息确认页面"},"component":()=>import('@/pages/EditForm/preview.vue')},
	{"path":"/form/index/:step","component":()=>import('@/pages/Form/index.vue')},
	{"path":"/Home/index","meta":{"title":"首页"},"component":()=>import('@/pages/Home/index.vue')},
	{"path":"/class-info/index","meta":{"title":"入学报到"},"component":()=>import('@/pages/class-info/index.vue')},
	{"path":"/zb/Application/Staging/artAndSportsStudents","meta":{"title":"艺体生报名"},"component":()=>import('@/pages/zb/Application/Staging/artAndSportsStudents.vue')},
	{"path":"/zb/Application/Staging/artAndSportsStudentsPlan","meta":{"title":"报名入学"},"component":()=>import('@/pages/zb/Application/Staging/artAndSportsStudentsPlan.vue')},
	{"path":"/zb/Application/Staging/examination","meta":{"title":"报名入学"},"component":()=>import('@/pages/zb/Application/Staging/examination.vue')},
	{"path":"/zb/Application/Staging/index","meta":{"title":"报名入学"},"component":()=>import('@/pages/zb/Application/Staging/index.vue')},
	{"path":"/zb/Application/Staging/required","meta":{"title":"报名入学"},"component":()=>import('@/pages/zb/Application/Staging/required.vue')},
	{"path":"/zb/Application/Staging/score","meta":{"title":"报名入学"},"component":()=>import('@/pages/zb/Application/Staging/score.vue')},
	{"path":"/zb/Application/Staging/selectSchool","meta":{"title":"报名信息"},"component":()=>import('@/pages/zb/Application/Staging/selectSchool.vue')},
	{"path":"/zb/Application/Staging/studyPlan","meta":{"title":"报名入学"},"component":()=>import('@/pages/zb/Application/Staging/studyPlan.vue')},
	{"path":"/zb/Application/Staging/verifyCustodian","meta":{"title":"报名信息"},"component":()=>import('@/pages/zb/Application/Staging/verifyCustodian.vue')},
	{"path":"/zb/Application/Staging/verifyStudent","meta":{"title":"报名信息"},"component":()=>import('@/pages/zb/Application/Staging/verifyStudent.vue')},
	{"path":"/zb/Application/preview","meta":{"title":"预览合同"},"component":()=>import('@/pages/zb/Application/preview.vue')},
	{"path":"/zb/Application/select","meta":{"title":"选择学生"},"component":()=>import('@/pages/zb/Application/select.vue')},
	{"path":"/zb/Application/verification","meta":{"title":"报名家长信息"},"component":()=>import('@/pages/zb/Application/verification.vue')},
	{"path":"/zb/Application/verifyPhone","meta":{"title":"验证手机号"},"component":()=>import('@/pages/zb/Application/verifyPhone.vue')},
	{"path":"/zb/Application/verifyStudent","meta":{"title":"学生身份验证"},"component":()=>import('@/pages/zb/Application/verifyStudent.vue')},
	{"path":"/zb/EnrollmentTeacher/index","meta":{"title":"选择招生老师"},"component":()=>import('@/pages/zb/EnrollmentTeacher/index.vue')},
	{"path":"/zb/Information/application","meta":{"title":"报名信息"},"component":()=>import('@/pages/zb/Information/application.vue')},
	{"path":"/zb/Information/applicationList","meta":{"title":"报名信息"},"component":()=>import('@/pages/zb/Information/applicationList.vue')},
	{"path":"/zb/Information/info","meta":{"title":"学生信息"},"component":()=>import('@/pages/zb/Information/info.vue')},
	{"path":"/zb/Information/parent","meta":{"title":"家长信息"},"component":()=>import('@/pages/zb/Information/parent.vue')},
	{"path":"/zb/Information/special","meta":{"title":"报名信息"},"component":()=>import('@/pages/zb/Information/special.vue')},
	{"path":"/zb/Information/student","meta":{"title":"绑定信息"},"component":()=>import('@/pages/zb/Information/student.vue')},
	{"path":"/formal/login/grade3","meta":{"title":"高三复读报名"},"component":()=>import('@/pages/zb/Login/grade3.vue')},
	{"path":"/formal/login/index","meta":{"title":"登录"},"component":()=>import('@/pages/zb/Login/index.vue')},
	{"path":"/formal/login/jump","component":()=>import('@/pages/zb/Login/jump.vue')},
	{"path":"/formal/login/login","meta":{"title":"报名入学"},"component":()=>import('@/pages/zb/Login/login.vue')},
	{"path":"/formal/login/phone","meta":{"title":"登录"},"component":()=>import('@/pages/zb/Login/phone.vue')},
	{"path":"/zb/Login/register","meta":{"title":"报名必填"},"component":()=>import('@/pages/zb/Login/register.vue')},
	{"path":"/zb/Order/binding","meta":{"title":"我的订单"},"component":()=>import('@/pages/zb/Order/binding.vue')},
	{"path":"/zb/Order/detail","meta":{"title":"查看详情"},"component":()=>import('@/pages/zb/Order/detail.vue')},
	{"path":"/zb/Order/detail3","meta":{"title":"查看详情"},"component":()=>import('@/pages/zb/Order/detail3.vue')},
	{"path":"/zb/Order/followUp","meta":{"title":"后续缴费学费"},"component":()=>import('@/pages/zb/Order/followUp.vue')},
	{"path":"/zb/Order/index","meta":{"title":"我的订单"},"component":()=>import('@/pages/zb/Order/index.vue')},
	{"path":"/zb/Order/offline","meta":{"title":"订单列表"},"component":()=>import('@/pages/zb/Order/offline.vue')},
	{"path":"/zb/Order/repeat","meta":{"title":"我的订单"},"component":()=>import('@/pages/zb/Order/repeat.vue')},
	{"path":"/zb/Order/toBePaid","meta":{"title":"待支付订单确认","onHistory":true},"component":()=>import('@/pages/zb/Order/toBePaid.vue')},
	{"path":"/zb/Order/toBePaid3","meta":{"title":"待支付"},"component":()=>import('@/pages/zb/Order/toBePaid3.vue')},
	{"path":"/zb/Payment/code","meta":{"title":"扫码付款"},"component":()=>import('@/pages/zb/Payment/code.vue')},
	{"path":"/zb/Payment/method","meta":{"title":"选择付款方式","onHistory":true},"component":()=>import('@/pages/zb/Payment/method.vue')},
	{"path":"/zb/Payment/wait","component":()=>import('@/pages/zb/Payment/wait.vue')},
	{"path":"/zb/Status/application","meta":{"title":"报名成功 "},"component":()=>import('@/pages/zb/Status/application.vue')},
	{"path":"/zb/Status/applicationFail","meta":{"title":"提示"},"component":()=>import('@/pages/zb/Status/applicationFail.vue')},
	{"path":"/zb/Status/cannot","meta":{"title":"系统提示"},"component":()=>import('@/pages/zb/Status/cannot.vue')},
	{"path":"/zb/Status/order","meta":{"title":"订单状态","onHistory":true},"component":()=>import('@/pages/zb/Status/order.vue')},
	{"path":"/zb/Status/register","meta":{"title":"注册成功"},"component":()=>import('@/pages/zb/Status/register.vue')},
	{"path":"/zb/Status/signing","meta":{"title":"签约状态"},"component":()=>import('@/pages/zb/Status/signing.vue')},
	{"path":"/zb/Status/submit","meta":{"title":"提交成功"},"component":()=>import('@/pages/zb/Status/submit.vue')},
	{"path":"/zb/Status/success","meta":{"title":"缴费成功"},"component":()=>import('@/pages/zb/Status/success.vue')}
]