import { createApp } from 'vue'
import './style.css'
import 'vant/lib/index.css'
import './assets/scss/zb_style.scss'
import './assets/scss/20230421.scss'
import App from './App.vue'
import router from './router'
import store from './store'
import tools from './utils/tools.js'
import { ConfigProvider } from 'vant'
import customComponents from './components/index.js'
import VConsole from 'vconsole'
import { setProgress } from './utils/utils.js'
import vant from "vant"
import axios from './axios'
import * as utils from "./utils/utils.js"
// import "./Mock/index.js"

const $utils = {
  install(vue){
    vue.config.globalProperties.$utils = utils;
  }
}

let app = createApp(App)
app.use(ConfigProvider)
tools.calcRem()
window.onresize = tools.calcRem;
app.config.globalProperties.$resource = import.meta.env.BASE_STATIC_URL;
app.config.globalProperties.$tools = tools
app.config.globalProperties.$axios = axios
app.use(router)
app.use(store)
app.use(vant);
app.use($utils);
app.use(customComponents);
if ((import.meta.env.MODE.indexOf('dev') !== -1 || import.meta.env.MODE.includes('test')) && navigator.platform !== 'Win32') {
  let vc = new VConsole()
  app.use(vc)
}
// 解决微信浏览器导致的缺陷(也有可能是输入法导致的,不过在手机自带浏览器不会出现此问题)
// 当正在输入的时候 , 点击输入法进入了其他页面 , 再回到当前页面 , 输入法实际是呼出状态 , 但是没有显示
// 导致页面底部输入是一片白色, 从而导致了窗口大小被压缩!
let activeElement
document.addEventListener('visibilitychange', () => {
  setTimeout(() => {
    if (document.hidden) {
      activeElement = document.activeElement
      activeElement.blur()
    } else {
      activeElement.focus()
    }
  }, 20)
})
// 解决微信浏览器软键盘弹出后,页面不回弹
let focusElement;
document.addEventListener("focusout",function (e) {
  focusElement = e.target;
})
window.addEventListener("resize",function (e) {
  let action = focusElement || document.activeElement;
  setTimeout(()=>{
    action.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
    focusElement = null;
  },20)
})
window.setProgress = setProgress()
app.mount('#app')
